body {
  margin: 0;
  color: white;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/src/assets/Bg.png');
  display: flex;
  flex-direction: column; /* Allow vertical stacking */
  justify-content: flex-start; /* Align items at the start */
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh; /* Allow content to grow beyond viewport */
  overflow: auto; /* Enable scrolling */
}

code {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
